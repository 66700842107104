import { useEffect } from 'react';

import { ADS_CONSTANTS } from '../AdsConstants';

export const useAppendScript = ({
  id,
  userId,
  onLoadImg,
}: {
  id: string;
  userId?: number;
  onLoadImg?: () => void;
}) => {
  useEffect(() => {
    const bannerContainer = document.getElementById(id);

    if (!bannerContainer) {
      return;
    }

    const script = document.createElement('script');
    script.src = ADS_CONSTANTS.scriptEPOMUrl;
    script.async = true;

    bannerContainer.appendChild(script);

    if (onLoadImg) {
      const observer = new MutationObserver(() => {
        const img = bannerContainer.querySelector('img');

        if (!img) {
          return;
        }

        observer.disconnect();

        if (img.complete) {
          onLoadImg();
        } else {
          img.onload = onLoadImg;
        }
      });

      observer.observe(bannerContainer, { childList: true, subtree: true });
    }

    return () => {
      if (bannerContainer) {
        bannerContainer.removeChild(script);
      }
    };
  }, [userId, id, onLoadImg]);
};
