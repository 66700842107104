import { useId } from 'react';

import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const StoreSearchInlineSearchBottomBannerTextButton = ({ userId }: { userId?: number }) => {
  const id = useId();

  useAppendScript({ id, userId });

  return (
    <div id={id}>
      <ins
        className={ADS_CONSTANTS.className}
        data-cp-user-id={userId}
        data-key={ADS_CONSTANTS.storeSearchInlineSearchBottomBannerTextButtonDataKey}
      />
    </div>
  );
};

export default StoreSearchInlineSearchBottomBannerTextButton;
