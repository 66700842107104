'use client';
import { AdsFeatureToggles } from '@api';

import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

export const useUserAdSettings = (): AdsFeatureToggles => {
  const {
    showStoreMainInlineCarouselBanner,
    showGlobalFloatingBottomRightBannerTextButton,
    showGlobalStickyHeaderBannerTextButton,
    showGlobalStickyBottomBannerMobile,
    showGlobalFloatingRightBanner,
    showGlobalFloatingLeftBanner,
    showGlobalInlineProfileMiddleBanner,
    showStoreSearchInlineSearchBottomBannerTextButton,
    showStoreAppInlineAppFormBottomRight,
    showLaunchpadDashboardInlineTopRightBanner,
    showLaunchpadMainInlineMiddleBanner,
    showStakingMainInlineListBottomBanner,
    showStoreMainInlineTopBannerTextButton,
  } = useFeatureToggles();

  return {
    showStoreMainInlineCarouselBanner: showStoreMainInlineCarouselBanner ?? false,
    showGlobalFloatingBottomRightBannerTextButton:
      showGlobalFloatingBottomRightBannerTextButton ?? false,
    showGlobalStickyHeaderBannerTextButton: showGlobalStickyHeaderBannerTextButton ?? false,
    showGlobalStickyBottomBannerMobile: showGlobalStickyBottomBannerMobile ?? false,
    showGlobalFloatingRightBanner: showGlobalFloatingRightBanner ?? false,
    showGlobalFloatingLeftBanner: showGlobalFloatingLeftBanner ?? false,
    showGlobalInlineProfileMiddleBanner: showGlobalInlineProfileMiddleBanner ?? false,
    showStoreSearchInlineSearchBottomBannerTextButton:
      showStoreSearchInlineSearchBottomBannerTextButton ?? false,
    showStoreAppInlineAppFormBottomRight: showStoreAppInlineAppFormBottomRight ?? false,
    showLaunchpadDashboardInlineTopRightBanner: showLaunchpadDashboardInlineTopRightBanner ?? false,
    showLaunchpadMainInlineMiddleBanner: showLaunchpadMainInlineMiddleBanner ?? false,
    showStakingMainInlineListBottomBanner: showStakingMainInlineListBottomBanner ?? false,
    showStoreMainInlineTopBannerTextButton: showStoreMainInlineTopBannerTextButton ?? false,
    // counters: {
    //   impressions: user?.attributes?.adSettings?.counters?.impressions || 0,
    // },
  };
};
